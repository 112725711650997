exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-blog-are-you-ready-for-the-indian-market-index-tsx": () => import("./../../../src/pages/blog/are-you-ready-for-the-indian-market/index.tsx" /* webpackChunkName: "component---src-pages-blog-are-you-ready-for-the-indian-market-index-tsx" */),
  "component---src-pages-blog-globalise-your-business-index-tsx": () => import("./../../../src/pages/blog/globalise-your-business/index.tsx" /* webpackChunkName: "component---src-pages-blog-globalise-your-business-index-tsx" */),
  "component---src-pages-blog-google-facebook-walmart-index-tsx": () => import("./../../../src/pages/blog/google-facebook-walmart/index.tsx" /* webpackChunkName: "component---src-pages-blog-google-facebook-walmart-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-events-index-tsx": () => import("./../../../src/pages/events/index.tsx" /* webpackChunkName: "component---src-pages-events-index-tsx" */),
  "component---src-pages-fund-your-business-index-tsx": () => import("./../../../src/pages/fund-your-business/index.tsx" /* webpackChunkName: "component---src-pages-fund-your-business-index-tsx" */),
  "component---src-pages-globalise-your-business-index-tsx": () => import("./../../../src/pages/globalise-your-business/index.tsx" /* webpackChunkName: "component---src-pages-globalise-your-business-index-tsx" */),
  "component---src-pages-grow-your-business-index-tsx": () => import("./../../../src/pages/grow-your-business/index.tsx" /* webpackChunkName: "component---src-pages-grow-your-business-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-people-bart-hergaarden-index-tsx": () => import("./../../../src/pages/our-people/bart-hergaarden/index.tsx" /* webpackChunkName: "component---src-pages-our-people-bart-hergaarden-index-tsx" */),
  "component---src-pages-our-people-brian-tellis-index-tsx": () => import("./../../../src/pages/our-people/brian-tellis/index.tsx" /* webpackChunkName: "component---src-pages-our-people-brian-tellis-index-tsx" */),
  "component---src-pages-our-people-doug-keaney-index-tsx": () => import("./../../../src/pages/our-people/doug-keaney/index.tsx" /* webpackChunkName: "component---src-pages-our-people-doug-keaney-index-tsx" */),
  "component---src-pages-our-people-index-tsx": () => import("./../../../src/pages/our-people/index.tsx" /* webpackChunkName: "component---src-pages-our-people-index-tsx" */),
  "component---src-pages-our-people-irwin-raj-index-tsx": () => import("./../../../src/pages/our-people/irwin-raj/index.tsx" /* webpackChunkName: "component---src-pages-our-people-irwin-raj-index-tsx" */),
  "component---src-pages-our-people-kamalesh-subramaniam-index-tsx": () => import("./../../../src/pages/our-people/kamalesh-subramaniam/index.tsx" /* webpackChunkName: "component---src-pages-our-people-kamalesh-subramaniam-index-tsx" */),
  "component---src-pages-our-people-nalini-raj-index-tsx": () => import("./../../../src/pages/our-people/nalini-raj/index.tsx" /* webpackChunkName: "component---src-pages-our-people-nalini-raj-index-tsx" */),
  "component---src-pages-our-people-ned-nedungadi-index-tsx": () => import("./../../../src/pages/our-people/ned-nedungadi/index.tsx" /* webpackChunkName: "component---src-pages-our-people-ned-nedungadi-index-tsx" */),
  "component---src-pages-our-people-peter-ficocelli-index-tsx": () => import("./../../../src/pages/our-people/peter-ficocelli/index.tsx" /* webpackChunkName: "component---src-pages-our-people-peter-ficocelli-index-tsx" */),
  "component---src-pages-our-people-philippe-vermande-index-tsx": () => import("./../../../src/pages/our-people/philippe-vermande/index.tsx" /* webpackChunkName: "component---src-pages-our-people-philippe-vermande-index-tsx" */),
  "component---src-pages-our-people-priyanka-rane-index-tsx": () => import("./../../../src/pages/our-people/priyanka-rane/index.tsx" /* webpackChunkName: "component---src-pages-our-people-priyanka-rane-index-tsx" */),
  "component---src-pages-our-people-rob-peck-index-tsx": () => import("./../../../src/pages/our-people/rob-peck/index.tsx" /* webpackChunkName: "component---src-pages-our-people-rob-peck-index-tsx" */),
  "component---src-pages-our-people-yogi-kalavalapalli-index-tsx": () => import("./../../../src/pages/our-people/yogi-kalavalapalli/index.tsx" /* webpackChunkName: "component---src-pages-our-people-yogi-kalavalapalli-index-tsx" */),
  "component---src-pages-our-story-index-tsx": () => import("./../../../src/pages/our-story/index.tsx" /* webpackChunkName: "component---src-pages-our-story-index-tsx" */),
  "component---src-pages-testimonials-index-tsx": () => import("./../../../src/pages/testimonials/index.tsx" /* webpackChunkName: "component---src-pages-testimonials-index-tsx" */)
}

