import React from 'react';

import Wrapper from '@context/Wrapper';

import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';

import '@styles/global.css';

import type { GatsbyBrowser } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <Wrapper>{element}</Wrapper>;
