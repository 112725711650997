import React from 'react';

import { DocumentContextProvider } from './Document';
import { MotionContextProvider } from './Motion';
import { ThemeContextProvider } from './Theme';

import type { PropsWithChildren } from 'react';

export default function Wrapper({ children }: PropsWithChildren) {
  return (
    <DocumentContextProvider>
      <ThemeContextProvider>
        <MotionContextProvider>{children}</MotionContextProvider>
      </ThemeContextProvider>
    </DocumentContextProvider>
  );
}
