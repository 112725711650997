import React, { createContext, useEffect, useState } from 'react';

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';

const ThemeContext = createContext<boolean | undefined>(undefined);

export function ThemeContextProvider({ children }: PropsWithChildren) {
  const [prefersDark, setPrefersDark]: [
    boolean | undefined,
    Dispatch<SetStateAction<boolean | undefined>>
  ] = useState();

  useEffect(() => {
    const prefersDarkMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );

    setPrefersDark(prefersDarkMediaQuery.matches);

    prefersDarkMediaQuery.addEventListener(
      'change',
      (event: MediaQueryListEvent) => setPrefersDark(event.matches),
      {
        passive: true,
      }
    );

    return () =>
      prefersDarkMediaQuery.removeEventListener(
        'change',
        (event: MediaQueryListEvent) => setPrefersDark(event.matches)
      );
  }, []);

  return (
    <ThemeContext.Provider value={prefersDark}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
