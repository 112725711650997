import React, { createContext, useEffect, useState } from 'react';

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';

const MotionContext = createContext<boolean | undefined>(undefined);

export function MotionContextProvider({ children }: PropsWithChildren) {
  const [prefersMotionReduced, setPrefersMotionReduced]: [
    boolean | undefined,
    Dispatch<SetStateAction<boolean | undefined>>
  ] = useState();

  useEffect(() => {
    const prefersMotionReducedQuery = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    );

    setPrefersMotionReduced(prefersMotionReducedQuery.matches);

    prefersMotionReducedQuery.addEventListener(
      'change',
      (event: MediaQueryListEvent) => setPrefersMotionReduced(event.matches),
      { passive: true }
    );

    return () =>
      prefersMotionReducedQuery.removeEventListener(
        'change',
        (event: MediaQueryListEvent) => setPrefersMotionReduced(event.matches)
      );
  }, []);

  return (
    <MotionContext.Provider value={prefersMotionReduced}>
      {children}
    </MotionContext.Provider>
  );
}

export default MotionContext;
