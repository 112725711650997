import React, { createContext, useEffect, useState } from 'react';

import type { PropsWithChildren } from 'react';

const DocumentContext = createContext<Document | undefined>(undefined);

export function DocumentContextProvider({ children }: PropsWithChildren) {
  const [documentLocal, setDocumentLocal] = useState<Document | undefined>();

  useEffect(() => setDocumentLocal(document), []);

  return (
    <DocumentContext.Provider value={documentLocal}>
      {children}
    </DocumentContext.Provider>
  );
}

export default DocumentContext;
